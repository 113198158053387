<template>
  <div
    class="tab-pane fade"
    id="member-setting"
    role="tabpanel"
    aria-labelledby="member-setting-tab"
  >
    <WeCard>
      <!-- Card Title -->
      <div class="row align-items-center">
        <div class="col">
          <h5 class="mb-0">Üyelik Ayarları</h5>
        </div>
        <div class="col-auto">
          <!-- Submit -->
          <div class="text-center">
            <span class="btn btn-success" v-on:click="onSave"
              ><i class="fas fa-save"></i> Kaydet</span
            >
          </div>
          <!-- ./Submit -->
        </div>
      </div>
      <!-- ./Card Title -->
      <hr />

      <!-- Member -->
      <WeSettingCard
        class="mb-3"
        name="member"
        title="Yeni Üyelik"
        description="Yeni üyelik oluşturulmasına izin ver"
        v-model="data.new"
      />
      <!-- Member -->

      <!-- Current Code Template -->
      <WeInput
        name="current_code_template"
        label="Cari Hesap Şablonu"
        placeholder="Örn. 120.99.****"
        v-model="data.current_code_template"
      />
      <!-- Current Code Template -->
    </WeCard>
  </div>
</template>
<script>
export default {
  name: "Member",
  data() {
    return {
      data: {
        new: this.settings["member.new"] == "1" ? 1 : 0,
        current_code_template: this.settings["member.current_code_template"],
      },
    };
  },
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    onSave() {
      this.settings["member.new"] = this.data.new;
      this.settings["member.current_code_template"] =
        this.data.current_code_template;
      this.$parent.$emit("on-save", this.settings);
    },
  },
};
</script>
